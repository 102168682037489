import { render, staticRenderFns } from "./ic-block.vue?vue&type=template&id=8e0af1aa&scoped=true"
import script from "./ic-block.vue?vue&type=script&lang=js"
export * from "./ic-block.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e0af1aa",
  null
  
)

export default component.exports