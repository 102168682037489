<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M10.0013 2.16663C5.4013 2.16663 1.66797 5.89996 1.66797 10.5C1.66797 15.1 5.4013 18.8333 10.0013 18.8333C14.6013 18.8333 18.3346 15.1 18.3346 10.5C18.3346 5.89996 14.6013 2.16663 10.0013 2.16663ZM3.33464 10.5C3.33464 6.81663 6.31797 3.83329 10.0013 3.83329C11.543 3.83329 12.9596 4.35829 14.0846 5.24163L4.74297 14.5833C3.85964 13.4583 3.33464 12.0416 3.33464 10.5ZM10.0013 17.1666C8.45964 17.1666 7.04297 16.6416 5.91797 15.7583L15.2596 6.41663C16.143 7.54163 16.668 8.95829 16.668 10.5C16.668 14.1833 13.6846 17.1666 10.0013 17.1666Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcBlock',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
